import api from "./apis/api"

class SalvarUsuario {

    static async salvarUsuario(
        cpf: string,
        emailEditado: string,
        nome: string,
        telefone: string,
    ): Promise<boolean> {
        var url = "users/create_or_update_many";

        const res = await api.post(url, JSON.stringify({
            "users": [
                {
                    "email": emailEditado,
                    "name": nome,
                    "identities": [
                        {
                            "type": "phone_number",
                            "value": telefone
                        }
                    ],
                    "user_fields": {
                        "cpf": cpf
                    }
                }
            ]
        }));

        let postResult: boolean;
        postResult = res.data.isValid;

        return postResult;
    }
}

export default SalvarUsuario;