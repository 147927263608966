import React, { useState, useEffect } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import ArrayValores from "../../Assets/ValoresComboMotivoContato";
import "./ComboMotivoContato.css";

function ComboMotivoContato(props: any) {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [seltecDrop, setSelectDrop] = useState("");
    const [seltecDrop2, setSelectDrop2] = useState("");
    const [seltecValue, setSelectValue] = useState("");
    const [seltecLabel, setSelectLabel] = useState("");
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const select = ArrayValores;

    useEffect(() => {
        props.setValue(seltecValue);
    }, [seltecValue]);

    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            {...props}
            onClick={() => setDropdownOpen(true)}
            className={"Dropdown"}
        >
            <DropdownToggle caret className={"DropdownToggle"} color={"white"}>
                {seltecLabel ? seltecLabel : "-"}
            </DropdownToggle>
            <DropdownMenu className={"DropdownMenu"} >
                {seltecDrop2
                    ? [
                        <DropdownItem
                            onClick={() => setSelectDrop2("")} className={"DropdownItem"}>
                            Voltar
                        </DropdownItem>,
                        select
                            .filter((item) => item.label === seltecDrop)
                            .map((item) =>
                                item.options
                                    ?.filter((item) => item.label === seltecDrop2)
                                    .map((sos) =>
                                        sos.options?.map((segundo) => (
                                            <DropdownItem
                                                onClick={() => {
                                                    setDropdownOpen(false);
                                                    setSelectLabel(segundo.label);
                                                    setSelectValue(segundo.value);
                                                }}
                                                className={"DropdownItem"}
                                            >
                                                <div>{segundo.label}</div>
                                            </DropdownItem>
                                        ))
                                    )
                            ),
                    ]
                    : seltecDrop
                        ? [
                            <DropdownItem onClick={() => setSelectDrop("")} className={"DropdownItem"}>
                                voltar
                            </DropdownItem>,
                            select
                                .filter((item) => item.label === seltecDrop)
                                .map((item) =>
                                    item.options?.map((sos) => (
                                        <DropdownItem
                                            onClick={() => {
                                                if (sos.options) {
                                                    setSelectDrop2(sos.label);
                                                } else {
                                                    setSelectLabel(sos.label);
                                                    setSelectValue(sos.value);
                                                    setDropdownOpen(false);
                                                }
                                            }}
                                            className={"DropdownItem"}
                                        >
                                            {sos.label}
                                        </DropdownItem>
                                    ))
                                ),
                        ]
                        : select.map((sos) => (
                            <DropdownItem
                                onClick={() => {
                                    if (sos.options) {
                                        setSelectDrop(sos.label);
                                    } else {
                                        setSelectLabel(sos.label);
                                        setSelectValue(sos.value);
                                        setDropdownOpen(false);
                                    }
                                }}
                                className={"DropdownItem"}
                            >
                                {sos.label}
                            </DropdownItem>
                        ))}
            </DropdownMenu>
        </Dropdown>
    );
}
export default ComboMotivoContato;