import React from "react";
//import logoHT from "../Assets/Images/LogoHT.png";
import logoHF from "../Assets/Images/LogoHF.png";
import logoNT from "../Assets/Images/LogoNT.png";
const url = window.location.href;
const urlRedirect = url.includes("naturaldaterra") ? "https://naturaldaterrahelp.zendesk.com/hc/pt-br" : "https://hortifrutihelp.zendesk.com/hc/pt-br";
const bgColor = url.includes("naturaldaterra") ? "#54412F" : "#A35E8E";

const Header = () => (
                <header
                    style={{ backgroundColor: bgColor, height: "80px", }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginLeft: "20%",
                            marginRight: "20%",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <div>
                            <img
                                alt="Logo Hortifruti"
                                src={url.includes("naturaldaterra") ? logoNT : logoHF}
                            />
                        </div>
                        <div>
                            <a
                                style={{
                                    color: "white",
                                    fontSize: "18px",
                                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
                                    textDecoration: "none",
                                }}
                                href={urlRedirect}
                            >Página inicial</a>
                        </div>
                    </div>
                </header>
);

export default Header;