import React, { useState, useEffect, } from "react";
import {
    Form,
    FormGroup,
    FormText,
    Button,
    Input,
    Label,
} from "reactstrap";
import "./Formulario.css";
import ImagemSAC from "../../Assets/Images/ImagemSAC.png";
import ComboMotivoContato from "../../Components/Combos/ComboMotivoContato";
import SalvarFormularioService from "../../Services/SalvarFormularioService";
import SalvarUsuarioService from "../../Services/SalvarUsuarioService";
import UploadFileService from "../../Services/UploadFileService";
import { useForm, } from "react-hook-form";
import Swal from "sweetalert2";

const Formulario = (props) => {
    const { register, handleSubmit, watch, setValue } = useForm();

    const cpf = watch("Cpf");
    const email = watch("Email");
    const nome = watch("Nome");
    const telefone = watch("Telefone");
    const motivoContato = watch("MotivoContato");
    const possuiPedido = watch("PossuiPedido");
    const numeroPedido = watch("NumeroPedido");
    const assunto = watch("Assunto");
    const descricao = watch("Descricao");
    const [emailPersonalizado, setEmailPersonalizado] = useState("");
    const [motivoEscolhido, setMotivoEscolhido] = useState("");
    const [renderizacaoInput, setRenderizacaoInput] = useState(false);
    const url = window.location.href;
    const [selectedFile, setSelectedFile] = useState();

    async function onSubmit(data) {
        loading("Enviando mensagem.");

        if (data.Cpf == "" || data.Cpf == undefined || data.Cpf == null) {
            warning("Cpf e um campo obrigatório !");
        } else if (emailPersonalizado == "" || emailPersonalizado == undefined || emailPersonalizado == null) {
            warning("Email e um campo obrigatório !");
        } else if (data.Nome == "" || data.Nome == undefined || data.Nome == null) {
            warning("Nome e um campo obrigatório !");
        } else if (data.Telefone == "" || data.Telefone == undefined || data.Telefone == null) {
            warning("Telefone e um campo obrigatório !");
        } else if (data.MotivoContato == "" || data.MotivoContato == undefined || data.MotivoContato == null) {
            warning("Motivo do contato e um campo obrigatório !");
        } else if (data.Assunto == "" || data.Assunto == undefined || data.Assunto == null) {
            warning("Assunto e um campo obrigatório !");
        } else if (data.Descricao == "" || data.Descricao == undefined || data.Descricao == null) {
            warning("Descição e um campo obrigatório !");
        } else {
            if (selectedFile === "" || selectedFile === undefined || selectedFile === null) {
                salvarForm(data, null);
            } else {
                UploadFileService.UploadFileService(selectedFile, selectedFile.name).then((value) => {
                    if (value.status === 201) {
                        salvarForm(data, JSON.parse(value.request.response).upload.token);
                    }
                    else {
                        warning("Falha na conexão, tente novamente !");
                    }
                });
            }
        }
    }
    
    async function salvarForm(data, token) {
        let empresa = url.match("naturaldaterra") ? "empresa_nt" : "empresa_hf";
        
        SalvarUsuarioService.salvarUsuario(
            data.Cpf,
            emailPersonalizado,
            data.Nome,
            data.Telefone
        );
        
        let formularioGrid = SalvarFormularioService.salvarFormulario(
            data.Cpf, //cpf
            emailPersonalizado,
            data.Email, //email
            data.Nome, //Nome cliente
            data.Telefone, //telefone
            data.MotivoContato, //motivoDoContato
            data.PossuiPedido, //possuiPedido
            (renderizacaoInput && numeroPedido != undefined ? data.NumeroPedido : null), //numeroPedido
            data.Assunto, //assunto
            data.Descricao, //descricao
            empresa, // Grupo Hortifruti ou Natural da Terra
            token // Token de anexo
        );
        
        if (formularioGrid.length === 0) {
            warning("Falha na conexão, tente novamente!");
        }
        else {
            resetFields();
            sucesso("🍉🍇🍓 Formulário enviado com sucesso. Em breve entraremos em contato.😉 🍓🍇🍉!");

            await delay(4000);
            if (url.match("naturaldaterra"))
                window.location.href = 'https://naturaldaterrahelp.zendesk.com/hc/pt-br';
            else
                window.location.href = 'https://hortifrutihelp.zendesk.com/hc/pt-br';
        }
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const warning = (mensagem) => {
        Swal.fire({
            title: "Atenção!",
            html: mensagem,
            imageWidth: 60,
            imageHeight: 60,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            icon: "warning",
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        });
    };

    const sucesso = (mensagem) => {
        Swal.fire({
            title: "Sucesso!",
            html: mensagem,
            imageWidth: 60,
            imageHeight: 60,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            icon: "success",
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
        });
    };

    const loading = (mensagem) => {
        Swal.fire({
            title: 'Por favor, aguarde!',
            html: mensagem,
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading()
            },
        });
    };

    const resetFields = () => {
        setValue("Cpf", "");
        setEmailPersonalizado("");
        setValue("Email", "");
        setValue("Nome", "");
        setValue("Telefone", "");
        setValue("MotivoContato", "");
        setValue("PossuiPedido", "");
        setValue("NumeroPedido", "");
        setValue("Assunto", "");
        setValue("Descricao", "");
        document.getElementById("Anexo").value = '';
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    useEffect(() => {
        setEmailPersonalizado(cpf + "@hortifruti.com.br");
    }, [cpf]);

    useEffect(() => {
        setValue("MotivoContato", motivoEscolhido);
    }, [motivoEscolhido]);

    return (
        <div>
            <div id="teste" className="body">
                <div className="formulario">
                    {(url.match("naturaldaterra")) ? (
                        <div>
                            <h1 id="H1NT">Formulário de suporte</h1><br />
                            <p>Receba retorno ágil de um atendente pelo seu e-mail e, posteriormente, consulte o andamento do seu chamado.</p><br />
                        </div>
                    ) :
                        <div>
                            <h1 id="H1HF">Formulário de suporte</h1><br />
                            <p>Receba retorno ágil de um atendente pelo seu e-mail e, posteriormente, consulte o andamento do seu chamado.</p><br />
                        </div>
                    }
                    <Form
                        id="formCliente"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormGroup>
                            <Label for="Cpf">
                                CPF
                            </Label>
                            <Input
                                {...register("Cpf")}
                                placeholder="CPF..."
                                id="Cpf"
                                type="text"
                                value={cpf}
                                onChange={(e) => {
                                    setValue("Cpf", e.target.value)
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Email">
                                Endereço de Email
                            </Label>
                            <Input
                                {...register("Email")}
                                placeholder="Email..."
                                id="Email"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setValue("Email", e.target.value)
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Nome">
                                Nome
                            </Label>
                            <Input
                                {...register("Nome")}
                                placeholder="Nome..."
                                id="Nome"
                                type="text"
                                value={nome}
                                onChange={(e) => {
                                    setValue("Nome", e.target.value)
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Telefone">
                                Telefone
                            </Label>
                            <Input
                                {...register("Telefone")}
                                placeholder="(00) 0000-0000"
                                id="Telefone"
                                type="number"
                                value={telefone}
                                onChange={(e) => {
                                    setValue("Telefone", e.target.value)
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="MotivoContato">
                                Motivo do Contato
                            </Label>
                            <ComboMotivoContato
                                {...register("MotivoContato")}
                                id="MotivoContato"
                                name="MotivoContato"
                                type="select"
                                setValue={setMotivoEscolhido} />
                            <FormText>
                                Por favor, preencha o campo relacionado a sua solicitação:
                            </FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label for="PossuiPedido">
                                Possui pedido? <FormText>(Opcional)</FormText>
                            </Label>
                            <Input
                                {...register("PossuiPedido")}
                                id="PossuiPedido"
                                type="select"
                                value={possuiPedido}
                                onChange={(e) => {
                                    setValue("PossuiPedido", e.target.value)
                                    if (e.target.value === "possui_pedido_sim") {
                                        setRenderizacaoInput(true);
                                    }
                                    else {
                                        setRenderizacaoInput(false);
                                    }
                                }}
                            >
                                <option value={""}>
                                    -
                                </option>
                                <option value={"possui_pedido_sim"}>
                                    Sim
                                </option>
                                <option value={"possui_pedido_não"}>
                                    Não
                                </option>
                            </Input>
                        </FormGroup>
                        {(renderizacaoInput) ? (
                            <FormGroup>
                                <Label for="NumeroPedido">
                                    Número do pedido <FormText>(Opcional)</FormText>
                                </Label>
                                <Input
                                    {...register("NumeroPedido")}
                                    placeholder="NumeroPedido..."
                                    id="NumeroPedido"
                                    type="number"
                                    value={numeroPedido}
                                    onChange={(e) => {
                                        setValue("NumeroPedido", e.target.value)
                                    }}
                                />
                            </FormGroup>
                        ) : null}
                        <FormGroup>
                            <Label for="Assunto">
                                Assunto
                            </Label>
                            <Input
                                {...register("Assunto")}
                                placeholder="Assunto..."
                                id="Assunto"
                                type="text"
                                value={assunto}
                                onChange={(e) => {
                                    setValue("Assunto", e.target.value)
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Descrição">
                                Descrição
                            </Label>
                            <Input
                                {...register("Descricao")}
                                id="Descrição"
                                type="textarea"
                                value={descricao}
                                onChange={(e) => {
                                    setValue("Descricao", e.target.value)
                                }}
                            />
                            <FormText>
                                Insira os detalhes de sua solicitação. Um membro de nossa equipe de suporte responderá assim que possível.
                            </FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label for="Anexo">
                                Anexo <FormText>(Opcional)</FormText>
                            </Label>   
                            <Input
                                id="Anexo"
                                type="file"
                                onChange={changeHandler}
                            />
                        </FormGroup>
                        {(url.match("naturaldaterra")) ? (
                            <Button
                                id="BNT"
                                className="botaoEnviar"
                                type="submit"
                            >
                                Enviar
                            </Button>
                        ) :
                            <Button
                                id="BHF"
                                className="botaoEnviar"
                                type="submit"
                            >
                                Enviar
                            </Button>
                        }
                    </Form>
                </div>
                <div className="imagem">
                    <img
                        alt="Mulher mexendo recebendo emails"
                        src={ImagemSAC}
                    />
                </div>
            </div>
        </div>
    );
};

export default Formulario;
