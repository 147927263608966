import React from "react";
const url = window.location.href;
const urlRedirect = url.includes("naturaldaterra") ? "https://naturaldaterra.com.br" : "https://hortifruti.com.br";
const bgColor = url.includes("naturaldaterra") ? "#54412F" : "#A35E8E";
const nomeEmpresa = url.includes("naturaldaterra") ? "Natural da Terra" : "Hortifruti";

const Header = () => (
                <header
                    style={{ backgroundColor: bgColor, height: "80px" }}
                >   
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "20%",
                        marginRight: "20%",
                        alignItems: "center",
                        height: "100%",
                    }}
                    >
                        <div>
                            <a
                                style={{
                                    color: "white",
                                    fontSize: "18px",
                                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
                                    textDecoration: "none",
                                }}
                                href={urlRedirect}
                            >
                                <div>
                                    <a
                                        style={{
                                            color: "white",
                                            fontSize: "18px",
                                            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
                                            textDecoration: "none",
                                        }}
                                        href={urlRedirect}
                                    >
                                        Copyright ©{nomeEmpresa}. Todos os direitos reservados.
                                    </a>
                                </div>
                                <div>
                                    <a
                                        style={{
                                            color: "white",
                                            fontSize: "18px",
                                            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
                                            textDecoration: "none",
                                        }}
                                        href={urlRedirect}
                                    >
                                        Feito com muito amor pra você.
                                    </a>
                                </div>
                            </a>
                        </div>
                    </div>
                </header>
);

export default Header;