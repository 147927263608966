import axios from 'axios';
import axiosRetry from 'axios-retry';

const token = 'YmFycmEuc3VwZXJ2aXNhb0Bob3J0aWZydXRpLmNvbS5ici90b2tlbjpKVEZxZDFPTlh5NzRJUmJmRHc0QWpwRjhoMXFHVkw2bnROWFM1YW5B'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${token}`,
    },
})

api.interceptors.request.use(
    async config => {
        return Promise.resolve(config)
    },
    error => {
        return Promise.reject(error)
    },
)

api.interceptors.request.use(async config => {
    if (token) {
        config.headers.Authorization = `Basic ${token}`;
    }
    return config;
});


axiosRetry(api, { retries: 30, retryDelay: () => 6000 })

export default api;