import api from "./apis/api"

class UploadFileService {
    static async UploadFileService(
        conteudo: string,
        nomeArquivo: string
    ): Promise<object> {
        var url = "uploads.json?filename=" + nomeArquivo;
        const res = await api.post(url, conteudo);
        return res;
    }
}

export default UploadFileService;