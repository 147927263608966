const valores = [
    { "label": "-", "value": "" },
    {
        "label": "Elogio", "options": [
            { "label": "Atendimento", "value": "elogio_atendimento" },
            { "label": "Colaborador(es)", "value": "elogio_colaborador-es" },
            { "label": "Loja", "value": "elogio_loja" },
            {
                "label": "Marca Própria", "options": [
                    { "label": "Marca Própria", "value": "elogio_marca_própria" },
                    { "label": "Cãomidinha", "value": "elogio__marca_própria__cãomidinha" },
                    { "label": "Comida congelada", "value": "elogio__marca_própria__comida_congelada" },
                    { "label": "Processados", "value": "elogio__marca_própria__processados" },
                    { "label": "Saladinha/Saladão", "value": "elogio__marca_própria__saladinha/saladão" },
                    { "label": "Quiche", "value": "_elogio__marca_própria__quiche" },
                    { "label": "Suco", "value": "elogio__marca_própria__suco" },
                    { "label": "Sopa", "value": "elogio__marca_própria__sopa" }
                ]
            },
            { "label": "Preço", "value": "elogio_preco" },
            { "label": "Produto", "value": "elogio_produto" },
            { "label": "Site", "value": "elogio_site" },
            { "label": "Sustentabilidade", "value": "elogio_sustentabilidade" },
            { "label": "Aplicativo", "value": "elogio__aplicativo" },
            { "label": "Pedido", "value": "elogio__pedido" },
            { "label": "Leve Natural", "value": "elogio__leve_natural" },
            { "label": "Fornecedor", "value": "elogio__fornecedor" },
            { "label": "Parceria", "value": "elogio__parceria" },
            { "label": "Aniversário HNT", "value": "elogio__aniversário_hnt" },
            { "label": "Postagem", "value": "elogio__postagem" },
            { "label": "Food To Save", "value": "elogio__food_to_save" },
            { "label": "Encomenda de Ceia", "value": "elogio__encomenda_de_ceia" }
        ]
    },
    {
        "label": "Informação", "options": [
            { "label": "Horário de funcionamento", "value": "informacao_horário_de_funcionamento" },
            { "label": "Cancelamento", "value": "informacao_cancelamento" },
            { "label": "Cadastro", "value": "informacao_cadastro" },
            { "label": "Colaboradores", "value": "informacao_colaboradores" },
            { "label": "Curriculo", "value": "informacao_curriculo" },
            { "label": "Cupom", "value": "informacao_cupom" },
            { "label": "Delivery", "value": "informacao_delivery" },
            { "label": "Endereço de Lojas", "value": "informacao_endereco_de_lojas" },
            { "label": "Entrega", "value": "informacao_entrega" },
            { "label": "Estorno", "value": "informacao_estorno" },
            { "label": "Fornecedor", "value": "informacao_fornecedor" },
            { "label": "Loja", "value": "informacao_loja" },
            { "label": "Leve Natural", "value": "informacao_leve_natural" },
            {
                "label": "Marca Própria", "options": [
                    { "label": "Cãomidinha", "value": "informacao::marca_propria::caomidinha" },
                    { "label": "Comida congelada", "value": "informacao::marca_propria::comida_congelada" },
                    { "label": "Quiche", "value": "informacao::marca_propria::quiche" },
                    { "label": "Saladinha/Saladão", "value": "informacao::marca_propria::saladinha/saladao" },
                    { "label": "Processados", "value": "informacao::marca_propria::processados" },
                    { "label": "Sopa", "value": "informação__marca_própria__sopa" }
                ]
            },
            { "label": "Nota Fiscal/ Recibo", "value": "informacao_nota_fiscal/_recibo" },
            { "label": "Outros", "value": "informacao_outros" },
            { "label": "Pagamento", "value": "informacao_pagamento" },
            { "label": "Pedido", "value": "informacao_pedido" },
            { "label": "Preço", "value": "informacao_preco" },
            { "label": "Produtos", "value": "informacao_produtos" },
            { "label": "Setores Internos", "value": "informacao_setores_internos" },
            { "label": "Site", "value": "informacao_site" },
            { "label": "NatuSou", "value": "informação__natusou" },
            { "label": "Aplicativo", "value": "informação__aplicativo" },
            { "label": "Parceria", "value": "informação__parceria" },
            { "label": "Conversa abandonada", "value": "informação__conversa_abandonada" },
            { "label": "Pagamento Recusado", "value": "informação__pagamento_recusado" },
            { "label": "Story Expirado", "value": "informação__story_expirado_" },
            { "label": "Pesquisa", "value": "informação__pesquisa_crm" },
            { "label": "Food To Save", "value": "informação__food_to_save" },
            { "label": "Cashback", "value": "informação__cashback" },
            { "label": "Encomenda de Ceia", "value": "informação__encomenda_de_ceia" }
        ]
    },
    {
        "label": "Reclamação", "options": [
            { "label": "Atendimento", "value": "reclamacao_atendimento" },
            { "label": "Cancelamento", "value": "reclamacao_cancelamento" },
            { "label": "Cadastro", "value": "reclamacao_cadastro" },
            { "label": "Cobrança indevida / Preco errado", "value": "reclamacao_cobranca_indevida_/_preco_errado" },
            { "label": "Colaborador(es)", "value": "reclamacao_colaborador-es" },
            {
                "label": "Cupom", "options": [
                    { "label": "Não aplicado", "value": "reclamacao::cupom_::nao_aplicado" },
                    { "label": "Errata", "value": "reclamacao::cupom_::errata" }
                ]
            },
            { "label": "Entrega nao realizada ou em atraso", "value": "reclamacao_entrega_nao_realizada_ou_em_atraso" },
            { "label": "Estorno", "value": "reclamacao_estorno" },
            {
                "label": "Incidentes", "options": [
                    { "label": "Discriminação", "value": "reclamacao::incidentes::discriminacao" },
                    { "label": "Queda em loja", "value": "reclamacao::incidentes::queda_em_loja" },
                    { "label": "Veículo danificado", "value": "reclamacao::incidentes::veiculo_danificado" },
                    { "label": "Roupa danificada", "value": "reclamacao::incidentes::roupa_danificada" },
                    { "label": "Furto", "value": "reclamacao::incidentes::furto" },
                    { "label": "Produto impróprio", "value": "reclamacao::incidentes::produto_improprio" },
                    { "label": "Lesão física", "value": "reclamacao::incidentes::lesao_fisica" }
                ]
            },
            { "label": "Loja", "value": "reclamacao_loja" },
            { "label": "Leve Natural", "value": "reclamacao_leve_natural" },
            {
                "label": "Marca Própria", "options": [
                    { "label": "Marca Própria", "value": "reclamação__marca_própria_" },
                    { "label": "Cãomidinha", "value": "reclamacao::marca_propria::caomidinha" },
                    { "label": "Comida congelada", "value": "reclamacao::marca_propria::comida_congelada" },
                    { "label": "Quiche", "value": "reclamacao::marca_propria::quiche" },
                    { "label": "Saladinha/Saladão", "value": "reclamacao::marca_propria::saladinha/saladao" },
                    { "label": "Processados", "value": "reclamacao::marca_propria::processados" },
                    { "label": "Sopa", "value": "reclamação__marca_própria__sopa" }
                ]
            },
            { "label": "Nota Fiscal/ Recibo", "value": "reclamacao_nota_fiscal/_recibo" },
            { "label": "Outros", "value": "reclamacao_outros" },
            { "label": "Pagamento", "value": "reclamacao_pagamento" },
            {
                "label": "Produto", "options": [
                    { "label": "Trocado", "value": "reclamacao::pedido::errado" },
                    { "label": "Mal acondicionado", "value": "reclamacao::produto::mal_acondicionado" },
                    { "label": "Ausente", "value": "reclamacao::produto::ausente" },
                    { "label": "Mal selecionado", "value": "reclamacao::produto::mal_selecionado" },
                    { "label": "Impróprio", "value": "reclamacao::produto::improprio" }
                ]
            },
            {
                "label": "Pedido", "options": [
                    { "label": "Trocado", "value": "reclamacao::pedido::trocado" }
                ]
            },
            { "label": "Preço", "value": "reclamacao_preço" },
            { "label": "Problema sistêmico", "value": "reclamacao_problema_sistêmico" },
            { "label": "Site", "value": "reclamacao_site" },
            { "label": "Substituição", "value": "reclamacao_substituicao" },
            { "label": "Sustentabilidade", "value": "reclamacao_sustentabilidade" },
            { "label": "Transtorno", "value": "reclamacao_transtorno" },
            { "label": "Aplicativo", "value": "reclamacao__aplicativo_" },
            { "label": "Pagamento Recusado", "value": "reclamacao__pagamento_recusado" },
            { "label": "Aniversário HNT", "value": "reclamação__aniversário_hnt" },
            { "label": "Postagem", "value": "reclamação__postagem" },
            {
                "label": "Cashback", "options": [
                    { "label": "Não aceito", "value": "reclamação__cashback__não_aceito" },
                    { "label": "Sistema inoperante", "value": "reclamação__cashback__sistema_inoperante" },
                    { "label": "Não aplicado", "value": "reclamação__cashback__não_aplicado" }
                ]
            },
            { "label": "Estacionamento", "value": "reclamação__estacionamento" },
            { "label": "Food To Save", "value": "reclamação__food_to_save" },
            { "label": "Encomenda de Ceia", "value": "reclamação__encomenda_de_ceia" }
        ]
    },
    {
        "label": "Solicitação", "options": [
            { "label": "Antecipar/Reagendar", "value": "solicitacao_antecipar/reagendar" },
            { "label": "Cancelamento", "value": "solicitacao_cancelamento" },
            { "label": "Inclusao/Exclusao de itens", "value": "solicitacao_inclusao/exclusao_de_itens" },
            { "label": "Outros", "value": "solicitacao_outros" },
            { "label": "Nota Fiscal/ Recibo", "value": "solicitacao_nota_fiscal/_recibo" },
            { "label": "Parceria", "value": "solicitacao_parceria" },
            { "label": "Redefinição de senha/Cadastro", "value": "solicitacao_redefinicao_de_senha/cadastro" },
            { "label": "Substituição", "value": "solicitacao_substituicao" },
            { "label": "Leve Natural", "value": "solicitação__leve_natural" },
            { "label": "Exclusão de cadastro Leve Natural", "value": "solicitação__exclusão_de_cadastro_leve_natural" },
            { "label": "Alteração de endereço", "value": "solicitação__alteração_de_endereço" },
            { "label": "Produto", "value": "solicitação__produto" },
            { "label": "Food To Save", "value": "solicitação__food_to_save" },
            { "label": "Encomenda de Ceia", "value": "solicitação__encomenda_de_ceia" }
        ]
    },
    {
        "label": "Sugestão", "options": [
            { "label": "Atendimento", "value": "sugestao_atendimento" },
            { "label": "Loja", "value": "sugestao_loja" },
            { "label": "Outros", "value": "sugestao_outros" },
            { "label": "Pagamentos", "value": "sugestao_pagamentos" },
            { "label": "Parceria", "value": "sugestao_parceria" },
            { "label": "Produtos", "value": "sugestao_produtos" },
            { "label": "Site", "value": "sugestao_site" },
            { "label": "Sustentabilidade", "value": "sugestao_sustentabilidade" },
            { "label": "Aplicativo", "value": "sugestão__aplicativo" },
            { "label": "Expansão", "value": "sugestão__expansão_" },
            { "label": "Food To Save", "value": "sugestão__food_to_save" },
            { "label": "Encomenda de Ceia", "value": "sugestão__encomenda_de_ceia" },
            {
                "label": "Marca Própria", "options": [
                    { "label": "Sopa", "value": "sugestão__marca_própria__sopa" }
                ]
            }
        ]
    }
]

export default valores;
