import api from "./apis/api"

class SalvarFormulario {

    static async salvarFormulario(
        cpf: string,
        emailEditado: string,
        email: string,
        nome: string,
        telefone: string,
        motivoDoContato: string,
        possuiPedido: string,
        numeroPedido: number,
        assunto: string,
        descricao: string,
        empresa: string,
        idArquivo: string,
    ): Promise<boolean> {
        var url = "tickets.json";

        const res = await api.post(url, JSON.stringify({
            "ticket": {
                "comment": {
                    "body": descricao,
                    "uploads": [
                        idArquivo
                    ], //decrição
                    "public": true
                },
                "priority": "normal",
                "subject": assunto, //Assunto
                "requester": {
                    "name": "Nome do Usuário",
                    "email": emailEditado //email editado
                },
                "group_id": 6229392739476,
                "brand_id": 360007084032,
                "ticket_forms": 6265348040340,
                "custom_fields": [
                    {
                        "id": 9385411272596,
                        "value": nome //nome
                    },
                    {
                        "id": 6311830931348,
                        "value": cpf //cpf
                    },
                    {
                        "id": 9385365718804,
                        "value": telefone //telefone
                    },
                    {
                        "id": 6312096872340,
                        "value": possuiPedido //possui pedido
                    },
                    {
                        "id": 6312122296212,
                        "value": numeroPedido //numero pedido
                    },
                    {
                        "id": 6231879948180,
                        "value": motivoDoContato //motivocontato
                    },
                    {
                        "id": 6311994751764,
                        "value": empresa //empresa
                    },
                    {
                        "id": 11068884358292,
                        "value": email //email
                    },
                    {
                        "id": 6311950565268,
                        "value": "canal_fale_conosco" //Canal de entrada
                    }
                ]
            }
        }));

        let formulario: boolean;
        formulario = res.data.isValid;

        return formulario;
    }
}

export default SalvarFormulario;